@use 'custom-properties';
@use 'semantic-variables';
@use 'sportsbook';

// base/type

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
legend,
button,
input,
select,
textarea,
li,
a,
strong,
span,
th,
caption,
td,
p,
footer,
small {
	color: rgba(var(--font-dark), 1);
	font-family: Outfit, sans-serif;
}

body {
	background-color: rgba(var(--base-01), 1);

	&.access-blocked {
		background-color: rgba(var(--base-01), 1);

		h2 {
			color: rgba(var(--font-dark), 1);
			font: var(--h2);
		}

		p {
			color: rgba(var(--font-supportive), 1);
		}

		a {
			color: rgba(var(--primary-accent-green), 1);
		}
	}
}

small {
	color: rgba(var(--font-supportive), 1);
}

.bonus-step__hydration-spinner-bg-color {
	background-color: rgba(var(--primary-accent-beige-light), 1);
}

.u-divider {
	border-color: rgba(var(--primary-accent-beige-light), 1);
}

// components/bonus-active

.bonus-active {
	.bonus-active__progress-lane {
		fill: rgba(var(--primary-accent-beige-dark), 1);
	}

	.bonus-active__progress-bar {
		fill: rgba(var(--primary-accent-red-light), 1);
	}
}

/*
.progress {
	.progress__number,
	.progress__number span {
		color: rgba(var(--primary-accent-beige-light), 1);
	}

	svg {
		path {
			stroke: rgba(var(--primary-accent-beige-light), 1);
		}

		.progress__meter {
			stroke: rgba(var(--primary-accent-green), 1);
		}
	}
} */

// components/buttons

.btn {
	background-color: rgba(var(--primary-accent-green), 1);
	color: rgba(var(--font-light), 1);

	&:disabled {
		background-color: rgba(var(--primary-accent-beige-dark), 1);
		color: rgba(var(--font-disabled), 1);
	}

	&.btn--secondary {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--secondary.js-support-button {
		background-color: rgba(var(--primary-accent-beige), 1);
		color: rgba(var(--font-dark), 1);
	}

	&.btn--tertiary {
		background-color: rgba(var(--primary-accent-beige-light), 1);
		color: rgba(var(--base-09), 1);
	}

	&.btn--clear,
	&.btn--login,
	&.btn--signup,
	&.btn--skip {
		color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--logout {
		background-color: rgba(var(--primary-accent-beige), 1);
		border-radius: 3px;
		color: rgba(var(--font-dark), 1);
	}

	&.btn--applepay {
		-apple-pay-button-style: black;
	}

	&.btn--light {
		background-color: rgba(var(--primary-accent-beige), 1);
		color: rgba(var(--base-09), 1);
	}

	&.btn--inverted {
		color: rgba(var(--primary-accent-beige-light), 1);
	}

	&.btn--visit {
		background-color: rgba(var(--primary-accent-red-dark), 1);
		color: rgba(var(--primary-accent-beige-light), 1);
	}

	&.btn--action-screen-confirm {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	&.btn--responsible-gaming-logout {
		background-color: transparent;
		color: rgba(var(--primary-accent-green), 1);
	}
}

// components/campaigns

.campaign__item {
	.description {
		color: rgba(var(--base-09), 1);
	}
}

// components/cc-form

.cc-form,
li.deposit-limit {
	&:focus-within .active-border {
		border-bottom: 2px solid rgba(var(--primary), 1);
	}
}

// components/collapse

.welcome-menu {
	.welcome-screen-collapse {
		background-color: rgba(var(--primary-accent-beige), 1);

		.collapse__continue-icon,
		.welcome-screen__summa-icon {
			fill: rgba(var(--primary-accent-green), 1);
		}

		p,
		span {
			color: rgba(var(--font-dark), 1);
		}

		tr:not(:last-of-type) {
			border-bottom: 1px solid rgba(var(--primary-accent-beige-dark), 1);
		}
	}
}

// deposit limits
.deposit-limits-collapse {
	.collapse__continue-icon {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.retrieve-limits-modal .default-limit-btn {
	border: 1px solid rgba(var(--primary-accent-green), 1);
	color: rgba(var(--primary-accent-green), 1);
}

// components/detail-accordion

.details-accordion {
	.summary-icon::after {
		filter: brightness(44%) sepia(1) hue-rotate(0deg) saturate(1);
	}
}

// components/filters

.user-options {
	background-color: rgba(var(--primary-accent-beige-light), 1);
	border-bottom: 1px solid rgba(var(--primary-accent-beige-dark), 1);
}

.filters {
	input {
		+ .filters__filter {
			background-color: rgba(var(--primary-accent-beige), 1);
			color: rgba(var(--base-09), 1);
		}

		&:checked {
			+ .filters__filter {
				color: rgba(var(--primary-accent-green), 1);
			}
		}
	}

	.sliding-border {
		background-color: rgba(var(--primary-accent-green), 1);
		height: 4px;
	}
}

.shop-product-filters {
	--filter-hover: rgba(--font-dark, 1);

	background: rgba(var(--primary-accent-beige-light), 1);

	a {
		color: rgba(var(--font-dark), 1);

		&::after {
			background-color: var(--filter-bar-inactive);
			bottom: 0;
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			width: 100%;
		}

		&.active {
			color: rgba(var(--primary-accent-green), 1) !important;
		}

		&.active::after {
			background-color: rgba(var(--primary-accent-green), 1) !important;
		}
	}
}

// components/flags
// components/form

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
	box-shadow: inset 0 0 0 9999px #fcf5e4 !important;
	-webkit-text-fill-color: #555 !important;
}

::-webkit-calendar-picker-indicator {
	filter: invert(30%) sepia(57%) saturate(3444%) hue-rotate(336deg) brightness(115%) contrast(84%);
}

.input-wrap {
	.input-wrap__input {
		border-bottom: 1px solid rgba(var(--primary-accent-beige-dark), 1);
	}

	&::after {
		background-color: rgba(var(--primary-accent-green), 1);
	}

	.slider-input {
		background-color: rgba(var(--primary-accent-beige), 1);
	}
}

.slider-container {
	.icon--sprite {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.legend,
.input-wrap__label {
	color: rgba(var(--base-09), 1);
}

::-webkit-input-placeholder {
	// color: rgba(var(--base-09), 1);
	color: rgba(var(--font-supportive), 1);
}

::-moz-placeholder {
	// color: rgba(var(--base-09), 1);
	color: rgba(var(--font-supportive), 1);
}

:-ms-input-placeholder {
	// color: rgba(var(--base-09), 1);
	color: rgba(var(--font-supportive), 1);
}
// New checkboxes

.custom-checkbox-list {
	li {
		border-bottom: 1px solid rgba(var(--primary-accent-beige-dark), 1);
	}
}

// components/game-category
// components/game-item

.item__urgent {
	background-color: rgba(var(--primary-accent-green-dark), 1);

	* {
		color: rgba(var(--base-01), 1);
	}
}

// components/game-list

.item__footer {
	border-right: 1px solid rgba(var(--primary-accent-beige), 1);
	border-top: 1px solid rgba(var(--primary-accent-beige), 1);

	.item__love-counter,
	.item__provider-title {
		color: rgba(var(--base-09), 1);
	}
}

.item__love {
	&.game-item__love--selected {
		.icon--sprite {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}

	.item__love-counter {
		color: rgba(var(--primary-accent-green), 1);
	}
}

.item__live-casino {
	border-bottom: 1px solid rgba(var(--primary-accent-beige), 1);

	.last-winning-number-container {
		.number-to-appear-text,
		.number-to-disappear-text {
			color: rgba(var(--primary-accent-beige-light), 1);
			font: var(--caption);
		}
	}
}

.game-item__jackpot {
	background-color: rgba(var(--base-02), 1);
	color: rgba(var(--base-09), 1);
}

// components/header
.account-icon-header {
	color: rgba(var(--base-01), 1);

	svg {
		&.icon--sprite {
			fill: rgba(var(--base-01), 1);
		}
	}
}

.page-head {
	.svg-logo {
		.casinostuen-black-logo {
			fill: rgba(var(--font-dark), 1);
		}
	}

	.btn--login {
		color: rgba(var(--primary-accent-beige-light), 1);
	}

	.page-head__title {
		color: rgba(var(--font-dark), 1);
	}

	.page-head__back.u-show {
		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}

	&.u-bg-trans {
		.page-head__title {
			color: rgba(var(--base-09), 1);
		}

		svg {
			fill: rgba(var(--base-09), 1);
		}

		.btn--login {
			color: rgba(var(--primary-accent-green), 1);
		}

		&.page-head-welcome {
			svg {
				fill: rgba(var(--base-01), 1);
			}
		}
	}

	&.country-select-header {
		.page-head__title {
			color: rgba(var(--base-09), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

// components/link-list

.link-list {
	.link-list__item {
		background-color: rgba(var(--primary-accent-beige), 1);

		&--rounded {
			border-radius: 3px;
		}

		&.disabled {
			svg {
				fill: rgba(var(--font-dark), 1);
			}

			.menu__main-title,
			.link-list__preamble,
			.link-list__continue-icon {
				color: rgba(var(--font-brand-01), 1);
			}
		}

		svg {
			fill: rgba(var(--primary-accent-red-light), 1);
		}

		.menu__main-title {
			color: rgba(var(--font-dark), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--primary-accent-green), 1);
		}

		.link-list__preamble,
		.link-list__value {
			color: rgba(var(--font-supportive), 1);
		}

		.link-list__more-icon {
			height: 20px;
			width: 20px;
		}

		.link-list__status-verified {
			color: rgba(var(--font-supportive), 1);
		}

		.link-list__status-message {
			color: rgba(var(--font-supportive), 1);
		}

		.link-list__status-unverified {
			color: rgba(var(--font-supportive), 1);
		}

		&.at-withdraw,
		&.at-transaction-history {
			background-color: rgba(var(--primary-accent-beige), 1);

			svg {
				fill: rgba(var(--primary-accent-green), 1);
			}

			.menu__main-title {
				color: rgba(var(--font-dark), 1);
			}
		}
	}

	&.link-list--flip-colors {
		.link-list__item {
			background-color: rgba(var(--primary-accent-beige), 1);

			svg {
				fill: rgba(var(--primary-accent-red-light), 1);
			}

			span {
				color: rgba(var(--base-09), 1);
			}

			.link-list__value {
				color: rgba(var(--font-supportive), 1);
			}

			.link-list__continue-icon {
				fill: rgba(var(--primary-accent-green), 1);
			}

			.link-list__status-verified {
				color: rgba(var(--primary-accent-green), 1);
			}

			.link-list__status-unverified {
				color: rgba(var(--base-09), 1);

				svg {
					fill: rgba(var(--base-09), 1);
				}
			}

			.dots-container {
				svg {
					fill: rgba(var(--primary-accent-green), 1);
				}
			}
		}
	}
}

.list-options {
	.link-list {
		.link-list__item {
			background-color: transparent;
			border-bottom: 1px solid rgba(var(--primary-accent-beige-dark), 1);

			.menu__main-title {
				color: rgba(var(--font-dark), 1);
			}

			svg {
				fill: rgba(var(--primary-accent-red-light), 1);
			}

			.link-list__continue-icon {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}

	.page-head {
		background-color: inherit;
	}
}

.bubble {
	.link-list__item {
		background-color: rgba(var(--primary-accent-beige), 1);

		.menu__main-title {
			color: rgba(var(--font-dark), 1);
		}

		.link-list__preamble {
			color: rgba(var(--font-supportive), 1);
		}

		.link-list-notification-color {
			color: rgba(var(--font-dark), 1);
		}

		.link-list__item-link {
			svg:first-child {
				fill: rgba(var(--primary-accent-red-light), 1);
			}

			svg:last-child {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

// .account-menu {
// 	.link-list {
// 		.link-list__item {
// 			background-color: rgba(var(--primary-accent-red-dark), 1);
// 			svg, .link-list__continue-icon {
// 				fill: rgba(var(--primary-accent-beige-light), 1);
// 			}
// 			.menu__main-title,
// 			.link-list__preamble,
// 			.link-list__value {
// 				color: rgba(var(--primary-accent-beige-light), 1);
// 			}
// 		}
// 	}
// }
// components/my notifications

.cta-button-container {
	background: rgba(var(--base-11), 1);
}

// components/notify

.notification-wrap {
	box-shadow: 0 0 4px black;
	color: rgba(var(--base-02), 1);

	.page-head__close {
		svg {
			fill: #fff;
		}
	}

	p,
	.notification-wrap__error-text-msg {
		color: rgba(var(--base-02), 1);
	}

	.notification-wrap__error-text-header {
		color: rgba(var(--base-02), 1);
	}

	.header-icon {
		svg {
			fill: #fff;
		}
	}

	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

// components/my notifications

.my-notification-item-wrapper .my-notification-details .my-notification-title-bar .my-notification-title {
	color: rgba(var(--font-dark), 1);
}

//notification - inline
.notification-inline-wrap {
	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}
// BINARY QUESTION MODAL

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--primary-accent-beige-light), 1);
	}

	.binary-question__button-container {
		.binary-question__yes,
		.binary-question__no,
		.binary-question__third {
			background: none;
		}
	}
}

// NOTIFY

.notify {
	&.notify--is-open {
		section {
			svg {
				fill: rgba(var(--base-02), 1);
			}

			h2,
			p {
				color: rgba(var(--base-02), 1);
			}
		}
	}

	&.notify--success {
		background-color: rgba(var(--success), 1) !important;
	}

	&.notify--failure {
		background-color: rgba(var(--error), 1) !important;
	}
}

.notify__head {
	.notify__head--quick-deposit {
		fill: var(--section-head-main-icon);
	}
}

// PROGRESS BAR

.notify__progress-bar {
	background-color: rgba(var(--base-11), 1);

	&.notify__progress-bar--fill-success {
		background-color: rgba(var(--success), 1);
		background-image: none;
	}

	&.notify__progress-bar--fill-fail {
		background-color: rgba(var(--error), 1);
		background-image: none;
	}
}

// OPTION BOX

// OVERLAY BEHIND MODAL

.deposit-siru,
.binary-question {
	&.notify--is-open {
		background-color: rgba(var(--base-11), 0.85);
	}
}

// COOKIE CONSENT

// For cookie-banner-policy, adding correct svg icon color

.wrapper-cookie-policy {
	svg {
		fill: rgba(var(--primary-accent-red-light), 1);
	}
}

.cookie-settings-header {
	.svg-logo {
		.casinostuen-black-logo {
			fill: rgba(var(--font-dark), 1);
		}
	}
}

// PAYMENT METHODS

.payment-methods {
	&__item {
		background-color: rgba(var(--primary-accent-beige), 1);

		&__logo {
			svg.logo-payment-method {
				background-color: #fff;
			}
		}

		&__chevron {
			svg.link-list__continue-icon {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

// Two Factor auth on execute deposit

.two-fact {
	&__error-label {
		color: rgba(var(--error), 1);
	}

	.btn:disabled {
		background-color: inherit;
	}
}

.notify--is-open {
	background-color: rgba(var(--primary-accent-red-dark), 1);

	&.reality-check-confirm {
		background-color: rgba(var(--primary-accent-beige-light), 1) !important;
		background-image: none;
	}
}

.option-box {
	background-color: rgba(var(--primary-accent-beige-light), 1);

	.option-box__inner {
		border-bottom: 1px solid rgba(var(--base-09), 0.5);
	}

	.btn--secondary {
		background-color: rgba(var(--primary-accent-beige-light), 1);
		color: rgba(var(--base-09), 1);
	}

	.user-options {
		background-color: transparent;

		.filters {
			input {
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				+ .filters__filter {
					background-color: rgba(var(--primary-accent-beige-dark), 1);
				}
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				&:checked {
					//TODO: fix this, reduce nesting REV-318
					//stylelint-disable-next-line selector-max-compound-selectors
					+ .filters__filter {
						background-color: rgba(var(--primary-accent-green), 1);
					}
				}
			}
		}
	}
}

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--primary-accent-beige-light), 1);

		.binary-question__main-title {
			color: rgba(var(--base-09), 1);
		}
	}

	.binary-question__p,
	.input-wrap__input,
	.two-fact__input-wrap input {
		color: rgba(var(--base-09), 1);
	}

	.input-wrap__input,
	.two-fact__input-wrap input {
		background-color: rgba(var(--primary-accent-beige), 1);
	}

	.binary-question__yes,
	.binary-question__no,
	.binary-question__third {
		border-top: 1px solid rgba(var(--base-09), 0.5);
		color: rgba(var(--font-dark), 1);
	}

	.binary-question__yes {
		border-left: 1px solid rgba(var(--base-09), 0.5);
		border-top: 1px solid rgba(var(--base-09), 0.5);
	}
}

// components/quick-deposit

.quick-deposit-wrapper {
	background-color: rgba(var(--primary-accent-beige-light), 1);

	.page-head__back {
		fill: rgba(var(--base-09), 1);
	}

	.page-head__title {
		color: rgba(var(--base-09), 1);
	}
}

.quick-deposit-response {
	&.quick-deposit-response--pending {
		background-color: rgba(var(--base-10), 1);
	}
}

// components/scroll-page-head

.page-head {
	/*
	.page-head__left-side {
		a,
		button {
			.icon--sprite {
				//fill: rgba(var(--primary-accent-green), 1);
			}
		}
	} */

	.page-head__right-side {
		button {
			color: rgba(var(--primary-accent-green), 1);
		}
	}

	.btn--skip,
	.btn--login,
	.page-head__title {
		color: rgba(var(--font-dark), 1);
	}

	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}

	&.u-bg-trans {
		.page-head__title {
			color: rgba(var(--font-dark), 1);
		}

		.btn--skip,
		.btn--login {
			color: rgba(var(--primary-accent-green), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}

	.page-head--bankid-email {
		.page-head__back {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

/*
.page-head-welcome {
	&.u-bg-trans {
		img {
			filter: var(--filter-white);
		}
	}
}
*/

// components/select

.custom-select {
	border-bottom: 1px solid rgba(var(--primary-accent-beige-dark), 1);

	&::after {
		fill: black;
		filter: brightness(0) saturate(100%) invert(47%) sepia(24%) saturate(602%) hue-rotate(43deg) brightness(97%)
			contrast(90%);
	}

	select {
		background-color: rgba(var(--primary-accent-beige), 1);
		color: rgba(var(--base-09), 1);

		&:focus {
			color: rgba(var(--base-09), 1);
		}

		&.is-valid {
			color: rgba(var(--base-09), 1);
		}

		.placeholder-active {
			color: rgba(var(--base-09), 1);
		}
	}

	option {
		color: rgba(var(--base-09), 1);
	}
}

// components/spinner
// components/support-nav

.support-nav {
	&.support-nav--is-open {
		background-color: rgba(var(--base-09), 0.85);
	}

	.support-nav__head {
		h2,
		h3 {
			color: rgba(var(--font-light), 1);
		}
	}

	.btn--secondary {
		background-color: rgba(var(--primary-accent-beige-light), 1);
		color: rgba(var(--font-dark), 1);
	}
}

.dots-container {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// components/proposed-locale-nav

.propose-locale-nav {
	.propose-locale-wrapper {
		border-radius: 3px;

		.btn--secondary {
			border-bottom: 0;
			border-top: 1px solid rgba(var(--propose-locale-border, var(--base-07)), 1);
			color: rgba(var(--font-dark), 1);
		}
	}
}

// components/tables

td {
	border-bottom: 1px solid rgba(var(--primary-accent-beige-dark), 1);
	color: rgba(var(--font-dark), 1);

	* {
		color: inherit;
	}

	&.u-text-r,
	p {
		color: rgba(var(--font-supportive), 1);
	}

	&:first-of-type {
		font: var(--label-bold);
	}
}

// components/tabs

.tab {
	* {
		color: rgba(var(--font-supportive), 1);
	}

	[aria-selected='true'] {
		* {
			color: rgba(var(--primary-accent-green), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

// components/winners
// layouts/account-settings

.account-settings-menu {
	.link-list .link-list__item {
		background-color: rgba(var(--primary-accent-beige), 1);

		.menu__main-title,
		.link-list__preamble,
		.link-list__continue-icon {
			color: var(--main-title);
		}

		.link-list__continue-icon {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

.verify-mail-support-btn {
	span {
		color: rgba(var(--font-light), 1);
	}
}

// layouts/bundles

//layouts/bonus terms & condition
.bonus-terms-wrapper {
	.terms-header__text-color {
		color: rgba(var(--primary-accent-beige-light), 1);
	}
}

// bonus-modal
.bonus-modal-view {
	.collapse__continue-icon {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// layouts/deposit

.deposit-bonus {
	background-color: rgba(var(--primary-accent-beige-light), 1);

	.bonus-item__image-wrapper {
		background-blend-mode: multiply;
		background-image: var(--brandContainerBackground);
		background-size: cover, 200px 554px;
	}
}

.multichoice-list {
	.multichoice-list__item {
		border-bottom: 1px solid rgba(var(--primary-accent-beige-dark), 1);
	}
}

// layouts/footer

.responsive-footer-logo .licenses-logos__iso-icon {
	background: url('../../mobile-core-assets/img/png/certification-logos/ISOIEC27001_Black.png') no-repeat;
	background-size: 80px 90px;
}

.paragraph-footer,
.footer-legal-content {
	color: rgba(var(--footer-text), 1);

	a {
		color: rgba(var(--font-brand-01), 1);
	}

	p {
		color: rgba(var(--footer-text), 1);
	}
}

.paragraph-footer span h2 {
	padding-bottom: 6px;
}

.other-logos-footer {
	&.not-payment-logos-footer li {
		background-color: transparent;
	}
}

footer a {
	color: rgba(var(--primary-accent-green), 1);
}

// layouts/info-box

.info-box {
	background-color: rgba(var(--primary-accent-beige-light), 1);

	.info-box-text,
	.info-box-value {
		color: rgba(var(--base-09), 1);
	}
}

// layouts/log-in

.user-options.user-options--login,
.user-options.user-options--register {
	background-color: transparent;
	border-bottom: 1px solid rgba(var(--primary-accent-red-dark), 1);

	input {
		+ .filters__filter {
			color: rgba(var(--base-09), 1);
		}

		&:checked {
			+ .filters__filter {
				color: rgba(var(--base-09), 1);
			}
		}
	}
}

// layouts/modal-view

.modal-view {
	background-color: rgba(var(--primary-accent-beige-light), 1);

	.page-head {
		background-color: inherit;
	}

	&.modal-view--verified {
		background-color: rgba(var(--success), 1);
	}
}

.sportsbook-relieve-limits-dialog,
.time-limits-dialog {
	background-color: rgba(var(--primary-accent-beige-light), 1);
}

.modal-view.modal-view--mobile {
	.page-head {
		background-color: rgba(var(--primary-accent-red), 1);
	}
}

section[type='user-checkmark'] {
	svg {
		fill: var(--main-icon);
	}

	h2 {
		color: var(--main-title);
	}

	p {
		color: var(--main-subtitle);
	}
}

// layouts/my-limits

.welcome-menu .limits-notification-wrapper,
.my-limits-menu .limits-notification-wrapper {
	background-color: rgba(var(--primary-accent-beige), 1);

	.icon-close--small {
		.icon--sprite {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}

	.limits-notification-message-wrapper {
		* {
			color: rgba(var(--font-dark), 1);
		}

		small {
			color: rgba(var(--font-supportive), 1);
		}
	}
}

.my-limits-menu {
	.accordion {
		a {
			color: rgba(var(--primary-accent-beige-light), 1);
		}

		.accordion__link {
			background-color: rgba(var(--primary-accent-beige), 1);
			color: rgba(var(--base-09), 1);

			a {
				color: rgba(var(--base-09), 1);
			}
		}

		.accordion__content {
			background-color: rgba(var(--primary-accent-beige), 1);

			p {
				color: rgba(var(--base-09), 1);
			}
		}
	}
}

// layouts/menu
// layouts/off-canvas

.menu {
	.page-head {
		background-color: rgba(var(--base-01), 1);
	}
}

.global-menu,
.account-menu {
	background-color: rgba(var(--base-01), 1);

	.link-list__status-message {
		color: rgba(var(--font-supportive), 1);
	}

	.last-login-timer--text,
	.last-login-timer--status {
		color: rgba(var(--font-supportive), 1);
	}
}

.global-menu {
	.page-head {
		.page-head__left-side {
			.icon--sprite {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

.global-menu {
	.page-head {
		.page-head__left-side {
			.icon--sprite {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

.account-menu {
	.page-head {
		/*
		.page-head__left-side {
			.icon--sprite {
				//fill: rgba(var(--primary-accent-green), 1);
			}
		} */

		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}

		.page-head__title {
			color: rgba(var(--font-dark), 1);
		}
	}
}

.menu {
	&.activity-item-list-container {
		.page-head {
			.page-head__back {
				fill: rgba(var(--primary-accent-green), 1);
			}

			.page-head__title {
				color: rgba(var(--base-09), 1);
			}
		}

		.activity-bg {
			background-color: rgba(var(--primary-accent-beige-light), 1);
		}
	}
}

// layouts/off-canvas-account

.user-balance {
	.user-balance__title {
		color: rgba(var(--font-supportive), 1);
	}

	.user-balance__sum {
		color: rgba(var(--font-dark), 1);
	}
}

.session-timer {
	color: rgba(var(--base-23), 1);

	&.session-timer--regulation-header {
		.session-timer-time {
			color: rgba(var(--base-01), 1);
		}
	}
}

// layouts/payment

.payment-result-header p {
	color: rgba(var(--font-supportive), 1);
}
// layouts/pusher
// layouts/recover-password
// layouts/result

[class^='section-head__SubTitle'],
.u-result-header {
	color: rgba(var(--font-supportive), 1);
}

.result {
	&.result--is-open {
		svg {
			fill: rgba(var(--base-02), 1);
		}

		.scs-bg svg {
			fill: rgba(var(--base-01), 1);
		}

		.nfs-bg svg {
			fill: rgba(var(--base-09), 1);
		}

		.failed-deposit {
			*:not(.btn),
			.btn- {
				color: rgba(var(--base-09), 1);
			}

			svg:not(.logo-payment-method) {
				fill: rgba(var(--base-09), 1);
			}

			.btn--secondary {
				color: rgba(var(--base-09), 1);
			}
		}
	}
}

.js-payment-result-information-message {
	.js-try-again-button-header {
		color: rgba(var(--primary-accent-green), 1);
	}
}

// layouts/risk

.risk-tag {
	background-color: rgba(var(--primary-accent-beige-light), 1);
}

// layouts/shop

.points {
	.points__svg {
		fill: rgba(var(--primary-accent-red), 1);
	}

	.points__inner {
		color: rgba(var(--base-02), 1);
	}
}

.offer-tag {
	svg {
		fill: rgba(var(--base-09), 1);
	}
}

// layouts/signup

.bottom-wrapper {
	background-color: rgba(var(--primary-accent-beige-light), 1);

	&.signup--open,
	&.log-in--open {
		background-color: initial;

		.page-head {
			background-color: inherit;
		}
	}
}

// layouts/sportsbook
// layouts/static-text

.wrapper-about,
.wrapper-responsible-gaming,
.wrapper-privacy_policy,
.wrapper-terms-and-conditions,
.wrapper-cookie-policy {
	.adaptable-header--pinned {
		--page-head-title: transparent;
		--page-head-link: rgba(var(--primary-accent-green), 1);
		--page-head-icon: rgba(var(--primary-accent-green), 1);

		background-color: rgba(var(--base-01), 1);
		border-bottom-color: transparent;
	}

	.filter-page-head-back svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.adaptable-header {
	&.adaptable-header--unfixed {
		.filter-page-head-back svg {
			fill: rgba(var(--base-01), 1);
		}
	}
}

.static-text.terms-conditions {
	.page-head {
		background-color: rgba(var(--base-01), 1);

		&.u-bg-trans {
			background-color: var(--sidebar-bg);
		}

		button svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
}

.static-text .svg-logo {
	.casinostuen-black-logo {
		fill: rgba(var(--font-dark), 1);
	}
}

// layouts/titles

.disclaimer-text {
	color: rgba(var(--primary-accent-green), 1);
}

.support-title {
	color: rgba(var(--font-supportive), 1);
}

// layouts/transaction-history

.transaction-history-inactive-layer {
	background-color: rgba(var(--base-11), 1);
}

.transaction-modal {
	background-color: rgba(var(--primary-accent-beige-light), 1);

	.page-head__title {
		color: rgba(var(--font-dark), 1);
	}
}

.js-account-history-details {
	.page-head__title {
		color: rgba(var(--font-dark), 1);
	}
}

// layouts/upload

.template-document-wrapper {
	border-bottom: 1px solid rgba(var(--primary-accent-beige-dark), 1);

	&::after {
		background-color: rgba(var(--primary-accent-red-light), 1);
	}
}

// layouts/welcome-screen

.welcome-menu {
	background-color: rgba(var(--primary-accent-beige-light), 1);

	.accordion {
		.accordion__link,
		.accordion__content {
			background-color: rgba(var(--primary-accent-beige), 1);
			color: rgba(var(--font-dark), 1);

			td {
				color: rgba(var(--base-02), 1);
			}

			tr {
				border-bottom: 1px solid rgba(var(--primary-accent-red), 1);
				color: rgba(var(--primary-accent-beige-light), 1);
			}
		}
	}

	.welcome__main-title {
		color: rgba(var(--font-dark), 1);
	}

	.welcome__p,
	.info-text {
		color: rgba(var(--font-supportive), 1);
	}

	.info-box {
		background-color: rgba(var(--primary-accent-beige-light), 1);
	}

	.link-list {
		.link-list__item {
			background-color: rgba(var(--primary-accent-beige), 1);
			color: rgba(var(--font-dark), 1);

			.menu__main-title {
				color: rgba(var(--font-dark), 1);
			}

			.link-list__continue-icon {
				fill: rgba(var(--primary-accent-green), 1);
			}
		}
	}
}

.welcome_animation {
	path {
		fill: rgba(var(--font-dark), 1);
	}
}

// layouts/withdrawal

.withdrawal--open {
	.page-head {
		background-color: rgba(var(--primary-accent-beige-light), 1);
	}
}

.pending-withdrawal {
	border-bottom: 1px solid rgba(var(--primary-accent-beige-dark), 1);

	.pending-withdrawal__action {
		svg {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}

	.pending-withdrawal__amount {
		color: rgba(var(--font-supportive), 1);
	}
}

// layouts/SEO content

.seo-content {
	.seo-content__color,
	* {
		color: rgba(var(--font-dark), 1);
	}

	p,
	button {
		font-family: Arial, sans-serif;
	}

	a,
	button {
		color: rgba(var(--primary-accent-green), 1);
	}
}

//Layout/MitID

/*
Removed code as it's part of danish locale. 
Code can be found in casinostuen branding file.
*/

// utils/placeholders
// utils/utility-classes

.u-box-bg {
	background-color: rgba(var(--primary-accent-beige-light), 1);
}

.u-text-grey {
	color: rgba(var(--font-supportive), 1);
}

.toggle-password {
	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

// components/payment/link-list
// Payment-provider tags

.payment-tag {
	color: rgba(var(--font-light), 1);

	&__popular {
		background-color: rgba(var(--primary-accent-yellow), 1);
		color: rgba(var(--font-dark), 1);
	}

	&__no-fee {
		background-color: rgba(var(--primary-accent-red-light), 1);
	}
}

.page-head__close.btn-result {
	svg {
		fill: rgba(var(--base-01), 1) !important;
	}
}

// Responsible Gaming

.my-limits-menu {
	.collapse-value {
		color: rgba(var(--font-supportive), 1);
	}
}

// Risk-profile

.risk-profile__description {
	.detail-view-header {
		p {
			color: rgba(var(--font-supportive), 1);
		}
	}
}

// Transaction History

.selected-filter-tags {
	span.selected-filter-tags_text {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}

	button::after {
		color: rgba(var(--primary-accent-green), 1) !important;
	}
}

.pill.disable span {
	color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
}

.qr-failure-icon {
	fill: rgba(var(--primary-accent-green), 1) !important;
}
